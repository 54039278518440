import { MdEditor, MdPreview } from "md-editor-rt";
import "md-editor-rt/lib/preview.css";
import { useEffect, useState } from "react";

export const Viewer: React.FC<{ content: string }> = ({ content }) => {
  return (
    <div>
      <MdPreview modelValue={content} />
    </div>
  );
};
