import Chart from "react-apexcharts";

export function Home() {
  let options = {
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      },
    ],
  };

  let chartConfig = {
    chart: {
      height: "100%",
      maxWidth: "100%",
      fontFamily: "Inter, sans-serif",
      dropShadow: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.55,
        opacityTo: 0,
        shade: "#1C64F2",
        gradientToColors: ["#1C64F2"],
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 6,
    },
    grid: {
      show: false,
      strokeDashArray: 4,
      padding: {
        left: 2,
        right: 2,
        top: 0,
      },
    },
    series: [
      {
        name: "New users",
        data: [6500, 6418, 6456, 6526, 6356, 6456],
        color: "#1A56DB",
      },
    ],
    xaxis: {
      categories: [
        "01 February",
        "02 February",
        "03 February",
        "04 February",
        "05 February",
        "06 February",
        "07 February",
      ],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
  };

  return (
    <div>
      <div className="">
        <div className="w-full max-w-sm rounded-lg bg-white p-4 shadow md:p-6">
          <div className="flex justify-between">
            <div>
              <h5 className="pb-2 text-3xl font-bold leading-none text-gray-900">
                32.4k
              </h5>
              <p className="text-base font-normal text-gray-500">
                Users this week
              </p>
            </div>
            <div className="flex items-center px-2.5 py-0.5 text-center text-base font-semibold text-green-500">
              12%
              <svg
                className="ms-1 h-3 w-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 14"
              >
                <path stroke="currentColor" d="M5 13V1m0 0L1 5m4-4 4 4" />
              </svg>
            </div>
          </div>
          <Chart options={chartConfig} series={options.series} type="area" />
          <div className="grid grid-cols-1 items-center justify-between border-t border-gray-200 dark:border-gray-700">
            <div className="flex items-center justify-between pt-5">
              <button
                id="dropdownDefaultButton"
                data-dropdown-toggle="lastDaysdropdown"
                data-dropdown-placement="bottom"
                className="inline-flex items-center text-center text-sm font-medium text-gray-500 hover:text-gray-900 dark:text-gray-400"
                type="button"
              >
                Last 7 days
                <svg
                  className="m-2.5 ms-1.5 w-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path stroke="currentColor" d="m1 1 4 4 4-4" />
                </svg>
              </button>
              <div
                id="lastDaysdropdown"
                className="z-10 hidden w-44 divide-y divide-gray-100 rounded-lg bg-white shadow"
              >
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                      Yesterday
                    </a>
                  </li>
                  <li>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                      Today
                    </a>
                  </li>
                  <li>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                      Last 7 days
                    </a>
                  </li>
                  <li>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                      Last 30 days
                    </a>
                  </li>
                  <li>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                      Last 90 days
                    </a>
                  </li>
                </ul>
              </div>
              <a
                href="#"
                className="inline-flex items-center rounded-lg px-3 py-2 text-sm font-semibold uppercase text-blue-600  hover:bg-gray-100 hover:text-blue-700 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:text-blue-500 dark:focus:ring-gray-700"
              >
                Users Report
                <svg
                  className="ms-1.5 h-2.5 w-2.5 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path stroke="currentColor" d="m1 9 4-4-4-4" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
