import { RxPencil2 } from "react-icons/rx";
import { Link } from "react-router-dom";

export const NavBar: React.FC<any> = () => {
  return (
    <nav className="flex h-16 w-full bg-white shadow">
      <div className="flex w-full items-center justify-between px-6">
        <div className="flex items-center justify-between">
          <div className="flex"></div>
        </div>
        <div className="flex-col">
          <Link
            to="/write"
            className="mx-4 font-medium text-gray-600 hover:text-gray-500"
          >
            <div className="flex items-center justify-center gap-2">
              <RxPencil2 />
              <div>Write</div>
            </div>
          </Link>
        </div>
      </div>
    </nav>
  );
};
