import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";

import {
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";

import Post, { AboutProps, LandingProps } from "../types/PostType";
import { firebaseConfig } from "./config";
import { toNonAccentVietnamese } from "./string";

export const signInWithGoogle = async () => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();
  try {
    await signInWithPopup(auth, provider);
    return true;
  } catch (error) {
    return false;
  }
};

export const getPost = async (title: string) => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  let id = title.replace(/\s/g, "-").toLowerCase();
  id = toNonAccentVietnamese(id);
  const postDocRef = doc(db, "posts", id);
  const postSnapshot = await getDoc(postDocRef);
  if (postSnapshot.exists()) {
    const data = postSnapshot.data();
    return {
      id: postSnapshot.id,
      title: data.title,
      content: data.content,
      coverImage: data.coverImage,
      tags: data.tags,
      createAt: data.createAt,
      updatedAt: data.updatedAt,
      visibility: data.visibility,
    } as Post;
  }
  return null;
};

export const getPosts = async () => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const postsCollectionRef = collection(db, "posts");
  const postsSnapshot = await getDocs(postsCollectionRef);
  const posts = postsSnapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      title: data.title,
      content: data.content,
      coverImage: data.coverImage,
      tags: data.tags,
      createAt: data.createAt,
      updatedAt: data.updatedAt,
      visibility: data.visibility,
    } as Post;
  });
  // short by createAt
  posts.sort((a, b) => {
    return b.createAt - a.createAt;
  });
  return posts;
};

export const createOrUpdatePost = async (post: Post) => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  let id = post.title.replace(/\s/g, "-").toLowerCase();
  id = toNonAccentVietnamese(id);
  try {
    const postDocRef = doc(db, "posts", id);
    await setDoc(postDocRef, {
      ...post,
    });
    return id;
  } catch (error) {
    const postsCollectionRef = collection(db, "posts", id);
    const docDetail = await addDoc(postsCollectionRef, {
      ...post,
    });
    return docDetail.id;
  }
};

export const deletePost = async (title: string) => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  let id = title.replace(/\s/g, "-").toLowerCase();
  id = toNonAccentVietnamese(id);
  const postDocRef = doc(db, "posts", id);
  await deleteDoc(postDocRef);
};

export const getTags = async () => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const tagsCollectionRef = collection(db, "tags");
  const tagsSnapshot = await getDocs(tagsCollectionRef);
  let tags = tagsSnapshot.docs.map((doc) => {
    const data = doc.data();
    const cloudTags = data.tags.map((item: string) => item);
    return cloudTags;
  });
  tags = tags.flat();
  tags.sort((a, b) => {
    return a.localeCompare(b);
  });
  return tags;
};

export const saveTags = async (tags: string[]) => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const tagsCollectionRef = collection(db, "tags");
  const tagsSnapshot = await getDocs(tagsCollectionRef);
  let docId = "";
  let cloudTags = tagsSnapshot.docs.map((doc) => {
    const data = doc.data();
    docId = doc.id;
    const cloudTags = data.tags.map((item: string) => item);
    return cloudTags;
  });
  cloudTags = cloudTags.flat();
  cloudTags = [cloudTags, tags].flat();
  cloudTags = cloudTags.filter((item, index) => {
    return cloudTags.indexOf(item) === index;
  });
  cloudTags.sort((a, b) => {
    return a.localeCompare(b);
  });
  const tagsDocRef = doc(db, "tags", docId);
  await setDoc(tagsDocRef, {
    tags: cloudTags,
  });
};

export const uploadImage = async (fileName: string, base64: string) => {
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);
  const storageRef = ref(storage, `landing/${fileName}`);
  const metadata = {
    contentType: "image/jpeg",
  };
  const uploadResult = await uploadString(
    storageRef,
    base64,
    "data_url",
    metadata,
  );
  return getDownloadURL(uploadResult.ref);
};

export const saveLanding = async (landing: LandingProps) => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  // check if landing exists or not then update or create
  const landingCollectionRef = collection(db, "landing");
  const landingSnapshot = await getDocs(landingCollectionRef);
  if (landingSnapshot.docs.length > 0) {
    const landingDocRef = doc(db, "landing", landingSnapshot.docs[0].id);
    await setDoc(landingDocRef, {
      ...landing,
    });
  } else {
    const landingDocRef = doc(db, "landing", "landing");
    await setDoc(landingDocRef, {
      ...landing,
    });
  }
};

export const getLanding = async () => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const landingCollectionRef = collection(db, "landing");
  const landingSnapshot = await getDocs(landingCollectionRef);
  const landing = landingSnapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      avatar: data.avatar,
      imageSpring: data.imageSpring,
      imageSummer: data.imageSummer,
      imageAutumn: data.imageAutumn,
      imageWinter: data.imageWinter,
      coverHeading: data.coverHeading,
      contentHeading: data.contentHeading,
      content: data.content,
    } as LandingProps;
  });
  return landing[0];
};

export const getAbout = async () => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const aboutCollectionRef = collection(db, "about");
  const aboutSnapshot = await getDocs(aboutCollectionRef);
  const about = aboutSnapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      avatar: data.avatar,
      content: data.content,
    };
  });
  return about[0];
};

export const saveAbout = async (about: AboutProps) => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  // check if about exists or not then update or create
  const aboutCollectionRef = collection(db, "about");
  const aboutSnapshot = await getDocs(aboutCollectionRef);
  if (aboutSnapshot.docs.length > 0) {
    const aboutDocRef = doc(db, "about", aboutSnapshot.docs[0].id);
    await setDoc(aboutDocRef, {
      ...about,
    });
  } else {
    const aboutDocRef = doc(db, "about", "about");
    await setDoc(aboutDocRef, {
      ...about,
    });
  }
};
