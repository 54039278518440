import { FC, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import {
  RxChevronRight,
  RxDesktop,
  RxInfoCircled,
  RxPencil2,
  RxPlay,
  RxReader,
  RxRocket,
} from "react-icons/rx";
import { Link, useLocation } from "react-router-dom";

import logo from "../../assets/cherry.png";

export const SideBar: React.FC<any> = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);

  const isActive = (url: string) => {
    return location.pathname === url;
  };

  const menu = [
    { name: "Dashboard", icon: <RxDesktop size={20} />, url: "/" },
    { name: "Landing", icon: <RxRocket size={20} />, url: "/landing" },
    { name: "About", icon: <RxInfoCircled size={20} />, url: "/about" },
    { name: "Write", icon: <RxPencil2 size={20} />, url: "/write" },
    { name: "Blog", icon: <RxReader size={20} />, url: "/blog" },
    { name: "Sample", icon: <RxPlay size={20} />, url: "/sample" },
  ];

  const handleLogout = () => {
    localStorage.removeItem("isLogged");
    window.location.reload();
  };
  return (
    <div className="sticky top-0 left-0">
      <div
        className={`absolute -right-3 top-14 flex h-7 w-7 items-center justify-center rounded-full border-2 bg-slate-700`}
      >
        <button
          onClick={() => {
            setIsOpen(!isOpen);
            localStorage.setItem("sidebar", isOpen ? "true" : "false");
          }}
          className="text-white"
        >
          <RxChevronRight
            size={20}
            className={`${isOpen ? "rotate-180" : ""}`}
          />
        </button>
      </div>
      <div
        className={`transition-w z-990 inset-y-0 block min-h-screen flex-wrap items-center justify-between overflow-y-auto border-r-2 border-dashed bg-white p-0 antialiased duration-200 xl:left-0 ${
          isOpen ? "w-64" : "w-0"
        }`}
        aria-expanded="false"
      >
        <div className="flex h-16">
          <a
            className="m-0 inline-flex items-center justify-center gap-2 whitespace-nowrap px-8 text-sm text-slate-700"
            href="/"
          >
            <img
              src={logo}
              className="h-full max-h-8 max-w-full transition-all duration-200"
              alt="main_logo"
            />
            <span className="ml-1 font-semibold transition-all duration-200">
              Admin Panel
            </span>
          </a>
        </div>
        <hr className="mt-0 h-px bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />

        <div className="h-sidenav block max-h-screen w-auto grow basis-full items-center overflow-auto">
          <ul className="mb-0 flex flex-col pl-0">
            {menu.map((item, index) => (
              <li className="mt-0.5 w-full" key={index}>
                <Link
                  className={`mx-2 my-0 flex items-center whitespace-nowrap rounded-lg px-4 py-2 text-sm text-slate-700 transition-colors ${
                    isActive(item.url)
                      ? "bg-[#eaedfc] font-semibold"
                      : "hover:bg-[#c3bfd3]"
                  }`}
                  to={item.url}
                >
                  <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center">
                    <i className="relative top-0 leading-normal text-blue-500">
                      {item.icon}
                    </i>
                  </div>
                  <span className="ease pointer-events-none ml-1 opacity-100 duration-300">
                    {item.name}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <hr className="mt-2 h-px bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />
        <li className="w-full cursor-pointer list-none">
          <div
            onClick={handleLogout}
            className={`mx-2 my-2 flex items-center whitespace-nowrap rounded-lg  px-4 py-2 text-sm text-slate-700 hover:bg-[#c3bfd3]`}
          >
            <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center">
              <i className="relative top-0 leading-normal text-blue-500">
                <FiLogOut />
              </i>
            </div>
            <span className="ease pointer-events-none ml-1 opacity-100 duration-300">
              Log Out
            </span>
          </div>
        </li>
      </div>
    </div>
  );
};
