import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { NavBar } from "./components/common/NavBar";
import { SideBar } from "./components/common/SideBar";
import About from "./pages/About";
import Blog from "./pages/Blog";
import ErrorPage from "./pages/ErrorPage";
import { Home } from "./pages/Home";
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import NewPost from "./pages/NewPost";
import { PostEdit } from "./pages/PostEdit";
import Sample from "./pages/Sample";

function App() {
  const [isLogged, setIsLogged] = useState(false);
  useEffect(() => {
    document.documentElement.classList.remove("dark");
    const isLogged = localStorage.getItem("isLogged");
    if (isLogged) {
      setIsLogged(true);
    }
  }, []);

  const onLogin = () => {
    const isLogged = localStorage.getItem("isLogged");
    if (isLogged) {
      setIsLogged(true);
    }
  };

  return (
    <div className="flex w-full">
      {isLogged ? (
        <Router>
          <div>
            <SideBar />
          </div>
          <div className="flex-1">
            <NavBar />
            <div className="p-5 mx-auto min-h-[calc(100vh-4rem)]">
              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="blog" element={<Blog />}></Route>
                <Route path="blog/*" element={<PostEdit />}></Route>
                <Route path="write" element={<NewPost />} />
                <Route path="landing" element={<Landing />} />
                <Route path="about" element={<About />} />
                <Route path="sample" element={<Sample />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </div>
          </div>
        </Router>
      ) : (
        <Login onLogin={onLogin} />
      )}
    </div>
  );
}

export default App;
