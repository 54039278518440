import { useEffect, useState } from "react";
import { PostCard } from "../components/common/PostCard";
import Post from "../types/PostType";
import { getPosts } from "../utils/firebase";

export default function Blog() {
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let posts = await getPosts();
        posts.sort((a, b) => b.updatedAt - a.updatedAt);
        setPosts(posts);
      } catch (error) {
        // Handle error here
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <div className="max-w-5xl">
        <div>
          <div className="flex justify-between py-4">
            <div className="text-2xl font-bold text-gray-800">Blog</div>
          </div>
        </div>
        <div className="flex flex-wrap gap-2">
          {posts.map((post, index) => (
            <PostCard post={post} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
