import { MdEditor } from "md-editor-rt";
import { FC, useEffect, useState } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";
import Datepicker from "react-tailwindcss-datepicker";
import Post from "../../types/PostType";
import { GetDate, GetTimestamp } from "../../utils/DateTime";
import { getTags } from "../../utils/firebase";
import { convertToJPEGBase64, uploadImageBase64 } from "../../utils/image";

type EditorProps = {
  title?: string;
  content?: string;
  coverImage?: string;
  tags?: string[];
  createAt: number;
  onChange: (item: string, value: any) => void;
};

type Tag = {
  value: string;
  label: string;
};

export const Editor: FC<EditorProps> = (editorProps: EditorProps) => {
  const [title, setTitle] = useState<string>();
  const [tags, setTags] = useState<Tag[]>([]);
  const [content, setContent] = useState<string>("");
  const [coverImage, setCoverImage] = useState<string>();
  const [createAt, setcreateAt] = useState<Date>(new Date());
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [tagOptions, setTagOptions] = useState<Tag[]>();
  const [dateValue, setDateValue] = useState({
    startDate: createAt,
    endDate: createAt,
  });

  useEffect(() => {
    setTitle(editorProps.title);
  }, [editorProps.title]);

  useEffect(() => {
    if (editorProps.tags === undefined) return;
    const tags: Tag[] = editorProps.tags.map((item) => ({
      label: item,
      value: item,
    }));
    setTags(tags);
  }, [editorProps.tags]);

  useEffect(() => {
    if (editorProps.content === undefined) return;
    setContent(editorProps.content);
  }, [editorProps.content]);

  useEffect(() => {
    setCoverImage(editorProps.coverImage);
    console.log(editorProps.coverImage);
  }, [editorProps.coverImage]);

  useEffect(() => {
    setcreateAt(new Date(editorProps.createAt));
    setDateValue({
      startDate: new Date(editorProps.createAt),
      endDate: new Date(editorProps.createAt),
    });
  }, [editorProps.createAt]);

  useEffect(() => {
    const getTagData = async () => {
      const cloudTags = await getTags();
      const tags: Tag[] = cloudTags.map((item: string) => ({
        value: item,
        label: item,
      }));
      setTagOptions(tags);
    };
    getTagData();
  }, []);

  const handleDateChange = (newValue: any) => {
    setDateValue(newValue);
    const selectedTimestamp = GetTimestamp(newValue.startDate);
    editorProps.onChange("createAt", selectedTimestamp);
  };

  const handleTitleChange = (e: any) => {
    editorProps.onChange("title", e.target.value);
  };

  const handleContentChange = (e: any) => {
    editorProps.onChange("content", e);
  };

  const handleTagsChange = (e: any) => {
    setTags(e);
    const tags = e.map((item: any) => item.value);
    // add new tags to tagOptions if not exist
    const newTags = tags.filter(
      (item: string) => !tagOptions?.map((tag) => tag.value).includes(item),
    );
    if (newTags.length > 0) {
      const newTagOptions = newTags.map((item: string) => ({
        value: item,
        label: item,
      }));
      setTagOptions([...tagOptions!, ...newTagOptions]);
    }
    editorProps.onChange("tags", tags);
  };

  const handleCoverImageChange = async (e: any) => {
    if (e.target.files.length === 0) return;
    convertToJPEGBase64(e.target.files[0]).then((base64) => {
      console.log(base64);
      editorProps.onChange("coverImage", base64);
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const isSidebarOpen = localStorage.getItem("sidebar") === "true";
      setSidebarOpen(isSidebarOpen);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // export type UploadImgEvent = (files: Array<File>, callBack: (urls: string[]) => void) => void;
  const onUploadImg = async (files: any, callback: any) => {
    const base64 = await convertToJPEGBase64(files[0]);
    const fileUrl = await uploadImageBase64(base64 as string);
    callback([fileUrl]);
  };

  return (
    <div>
      <div className="flex gap-2 items-center">
        <div>Create At</div>
        <div className="relative mb-2 rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500">
          <Datepicker
            asSingle
            primaryColor={"blue"}
            value={dateValue}
            onChange={handleDateChange}
            useRange={false}
            showFooter={false}
            showShortcuts={true}
            displayFormat="YYYY-MM-DD"
          />
        </div>
      </div>
      <div className="pb-2">
        <div>
          Title <span className="text-red-600">*</span>
        </div>
        <input
          type="text"
          onChange={(e) => handleTitleChange(e)}
          placeholder="Title"
          value={title}
          className="block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 text-sm font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
        ></input>
      </div>
      <div className="pb-2">
        <div>
          Tags <span className="text-red-600">*</span>
        </div>
        <Creatable
          options={tagOptions}
          value={tags}
          isMulti
          placeholder="Tags"
          onChange={(e) => handleTagsChange(e)}
          className="py-2 rounded-lg"
        />
      </div>
      <div className="pb-2">
        <div>
          Thumbnail <span className="text-red-600">*</span>
        </div>
        <div className="flex items-center justify-center w-full">
          <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
            <div className="flex items-center justify-between w-full p-5">
              <img
                src={coverImage}
                alt=""
                className="w-auto h-60 rounded-lg"
              ></img>
              <div>
                <svg
                  className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG or GIF (MAX. 800x400px)
                </p>
              </div>
            </div>
            <input
              id="dropzone-file"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={(e) => handleCoverImageChange(e)}
            />
          </label>
        </div>
      </div>
      <div
        className={`${sidebarOpen ? "w-[calc(100vw-4rem)]" : "w-[calc(100vw-20rem)]"} mb-20`}
      >
        <div>
          Content <span className="text-red-600">*</span>
        </div>
        <MdEditor
          modelValue={content}
          toolbarsExclude={["save", "catalog", "htmlPreview", "github"]}
          onUploadImg={onUploadImg}
          onChange={handleContentChange}
          language="en-US"
        />
      </div>
    </div>
  );
};
