import React, { useEffect, useRef, useState } from "react";
import { FiMoreVertical } from "react-icons/fi";

export const DropDown: React.FC<any> = ({ dropDownData, itemClicked }) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null); // Ref to the dropdown container

  const handleOnClick = (e: any) => {
    setOpen(false); // Close dropdown when an item is clicked
    itemClicked(e);
  };

  useEffect(() => {
    // Function to check if the click is outside the dropdown
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    // Attach the listener to the document
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]); // Only re-run if the 'open' state changes

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          onClick={() => setOpen(!open)}
          className="flex h-6 w-6 items-center justify-center rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm hover:bg-slate-200"
        >
          <FiMoreVertical className="h-5 w-5 text-gray-900" />
        </button>
      </div>
      {open && (
        <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1" role="none">
            {dropDownData.map((item: any, index: number) => (
              <div
                key={index}
                onClick={() => handleOnClick(item.name)}
                className="block cursor-pointer rounded-md px-4 py-2 text-sm text-gray-700 hover:bg-slate-200 hover:text-gray-900"
                role="menuitem"
                id={`menu-item-${index}`}
              >
                <div className="flex gap-2">
                  {item.icon}
                  {item.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
