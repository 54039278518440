import { get } from "http";
import { useEffect, useState } from "react";
import { FiArrowLeft, FiSave, FiTrash } from "react-icons/fi";
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router-dom";
import { Editor } from "../components/common/Editor";
import Post from "../types/PostType";
import {
  createOrUpdatePost,
  deletePost,
  getPost,
  saveTags,
} from "../utils/firebase";
import { uploadImageBase64 } from "../utils/image";
import { sleep } from "../utils/time";

export const PostEdit: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const postTitle = location.pathname.split("/").pop() as string;

  const [title, setTitle] = useState<string>();
  const [newTitle, setNewTitle] = useState<string>();
  const [createAt, setCreateAt] = useState<number>(Date.now());
  const [tags, setTags] = useState<string[]>([]);
  const [coverImage, setCoverImage] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [visibility, setVisibility] = useState<boolean>(false);

  const [popup, setPopup] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const cloudPost = await getPost(postTitle);
        if (cloudPost === null) {
          navigate(`/blog`);
          return;
        }
        setTitle(cloudPost.title);
        setNewTitle(cloudPost.title);
        setCreateAt(cloudPost.createAt);
        setTags(cloudPost.tags);
        setCoverImage(cloudPost.coverImage);
        setContent(cloudPost.content);
        setVisibility(cloudPost.visibility);
      } catch (error) {
        // Handle error here
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleContentChange = (title: string, value: any) => {
    switch (title) {
      case "title":
        setNewTitle(value);
        break;
      case "content":
        setContent(value);
        break;
      case "coverImage":
        setCoverImage(value);
        break;
      case "tags":
        setTags(value);
        break;
      case "createAt":
        setCreateAt(value);
        break;
      default:
        break;
    }
  };

  const getPostLocal = () => {
    return {
      title: newTitle,
      content: content,
      coverImage: coverImage,
      tags: tags,
      createAt: createAt,
      updatedAt: new Date().getTime(),
      visibility: visibility,
    } as Post;
  };

  const handleVisibility = async () => {
    setVisibility(!visibility);
    await createOrUpdatePost(getPostLocal());
  };

  const handleSave = async () => {
    setLoading(true);
    if (title === undefined) return;
    let titleChanged = false;
    if (title !== newTitle) {
      titleChanged = true;
      await deletePost(title);
      setTitle(newTitle);
    }
    if (!coverImage.startsWith("http")) {
      const imageUrl = await uploadImageBase64(coverImage);
      setCoverImage(imageUrl);
    }
    const post = getPostLocal();
    console.log(post);
    await saveTags(post.tags);
    await createOrUpdatePost(post);

    await sleep(1000);
    setLoading(false);
    if (titleChanged) {
      navigate(`/blog/${newTitle?.replace(/\s/g, "-").toLowerCase()}`);
    }
  };

  const handleDelete = async () => {
    setPopup(true);
  };

  const handleConfirmDelete = async (e: boolean) => {
    if (e) {
      if (title === undefined) return;
      setLoading(true);
      await deletePost(title);
      await sleep(1000);
      setLoading(false);
      navigate(`/blog`);
    } else {
      setPopup(false);
    }
  };

  return (
    <div className="">
      <div className="flex h-10 gap-2 py-2">
        {/* Back to blog */}
        <button
          onClick={() => navigate("/blog")}
          className="felx items-center justify-center rounded-md px-1 py-1 text-slate-700 hover:bg-slate-200 hover:text-slate-500"
        >
          <FiArrowLeft size={20} />
        </button>
        <button
          className="felx items-center justify-center rounded-md px-1 py-1 text-slate-700 hover:bg-slate-200 hover:text-slate-500"
          onClick={handleVisibility}
        >
          {visibility ? <PiEyeLight size={20} /> : <PiEyeSlash size={20} />}
        </button>
        <button
          onClick={handleSave}
          className="felx items-center justify-center rounded-md px-1 py-1 text-slate-700 hover:bg-slate-200 hover:text-slate-500"
        >
          <FiSave size={20} />
        </button>
        <button
          onClick={handleDelete}
          className="felx items-center justify-center rounded-md px-1 py-1 text-slate-700 hover:bg-slate-200 hover:text-slate-500"
        >
          <FiTrash size={20} />
        </button>
      </div>
      <Editor
        title={newTitle}
        content={content}
        coverImage={coverImage}
        tags={tags}
        createAt={createAt}
        onChange={handleContentChange}
      />
      {popup && (
        <div className="absolute top-0 left-0 inset-0 z-50 mx-0 h-full w-full justify-center bg-slate-200 opacity-70"></div>
      )}
      {popup && (
        <div className="absolute top-0 left-0 z-50 mx-0 h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden">
          <div className="relative max-w-md mx-auto h-full flex items-center">
            <div className="relative rounded-lg bg-white p-4 text-center shadow sm:p-5 dark:bg-gray-800">
              <button
                type="button"
                onClick={() => handleConfirmDelete(false)}
                className="absolute right-2.5 top-2.5 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  aria-hidden="true"
                  className="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <svg
                className="mx-auto mb-3.5 h-11 w-11 text-gray-400 dark:text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <p className="mb-4 text-gray-500 dark:text-gray-300">
                Are you sure you want to delete this item?
              </p>
              <div className="flex items-center justify-center space-x-4">
                <button
                  data-modal-toggle="deleteModal"
                  type="button"
                  onClick={() => handleConfirmDelete(false)}
                  className="focus:ring-primary-300 rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-600"
                >
                  No, cancel
                </button>
                <button
                  type="submit"
                  onClick={() => handleConfirmDelete(true)}
                  className="rounded-lg bg-red-600 px-3 py-2 text-center text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
                >
                  Yes, I'm sure
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`absolute top-0 left-0 backdrop-blur-sm w-full h-full ${!loading && "hidden"}`}
      >
        <div className="flex justify-center items-center h-[calc(100vh-100px)]">
          <svg
            aria-hidden="true"
            className="w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
