import "md-editor-rt/lib/style.css";
import { useEffect, useState } from "react";
import { FiSave } from "react-icons/fi";
import { Editor } from "../components/common/Editor";
import Post from "../types/PostType";
import { DateTimeString } from "../utils/DateTime";
import { uploadImageBase64 } from "../utils/image";

import { FiUploadCloud } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { createOrUpdatePost, saveTags } from "../utils/firebase";

export default function NewPost() {
  const postProps: Post = {
    title: "Lorem Ipsum",
    content:
      "Some introduce content.\n# Benefits of Blogging\nThere are a variety of reasons why your business should blog...",
    coverImage:
      "https://assets.justinmind.com/wp-content/uploads/2018/11/Lorem-Ipsum-alternatives.png",
    tags: ["Life", "Book", "Language"],
    createAt: Date.now(),
    updatedAt: Date.now(),
    visibility: false,
  };
  const [title, setTitle] = useState<string>(postProps.title);
  const [createAt, setCreateAt] = useState<number>(postProps.createAt);
  const [updateAt, setUpdateAt] = useState<number>(postProps.updatedAt);
  const [tags, setTags] = useState<string[]>(postProps.tags);
  const [coverImage, setCoverImage] = useState<string>(postProps.coverImage);
  const [content, setContent] = useState<string>(postProps.content);
  const [visibility, setVisibility] = useState<boolean>(postProps.visibility);

  const [uploading, setUploading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  const getPostLocal = () => {
    return {
      title: title,
      content: content,
      coverImage: coverImage,
      tags: tags,
      createAt: createAt,
      updatedAt: new Date().getTime(),
      visibility: visibility,
    } as Post;
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [showAlert]);

  const handleSubmit = async () => {
    setUploading(true);
    const post = getPostLocal();
    await saveTags(tags);
    post.visibility = true;
    if (!coverImage.startsWith("http")) {
      const imageUrl = await uploadImageBase64(coverImage);
      post.coverImage = imageUrl;
    }
    await createOrUpdatePost(post);
    navigate(`/blog`);
    setUploading(false);
  };

  const handleSave = async () => {
    await saveTags(tags);
    setVisibility(false);
    setUpdateAt(Date.now());
    // Check if cover image is url or base64
    if (!coverImage.startsWith("http")) {
      const imageUrl = await uploadImageBase64(coverImage);
      setCoverImage(imageUrl);
    }
    await createOrUpdatePost(getPostLocal());
    setShowAlert(true);
  };

  const handleEditorChange = (title: string, value: any) => {
    switch (title) {
      case "title":
        setTitle(value);
        break;
      case "content":
        setContent(value);
        break;
      case "coverImage":
        setCoverImage(value);
        break;
      case "tags":
        setTags(value);
        break;
      case "createAt":
        setCreateAt(value);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="">
        {/* Menu */}
        <div className="flex justify-end pb-2">
          <div className="flex gap-2">
            <div
              className={`cursor-pointer rounded-lg bg-[#212b36] px-4 py-2 text-white ${uploading ? "pointer-events-none opacity-50" : ""}`}
              onClick={handleSave}
            >
              <div className="flex items-center justify-center gap-2 text-sm">
                <FiSave />
                <div>Save</div>
              </div>
            </div>
            <div
              className={`cursor-pointer gap-2 rounded-lg bg-[#212b36] px-4 py-2 text-white ${uploading ? "pointer-events-none opacity-50" : ""}`}
              onClick={handleSubmit}
            >
              <div className="flex items-center justify-center gap-2 text-sm">
                <FiUploadCloud />
                <div>Publish</div>
              </div>
            </div>
          </div>
        </div>
        {/* Editor */}
        <Editor
          title={title}
          content={content}
          coverImage={coverImage}
          tags={tags}
          createAt={createAt}
          onChange={handleEditorChange}
        />
        {showAlert && (
          <div
            className={`z-999 trans duration-5000 absolute right-4 top-4 mb-4 rounded-lg bg-green-50 p-4 text-sm text-green-800 transition-all ease-in-out dark:bg-gray-800 dark:text-green-400 ${
              showAlert
                ? "translate-x-0 opacity-100"
                : "translate-x-full opacity-0"
            }`}
            role="alert"
          >
            <span className="font-medium">Save successfully.</span>
          </div>
        )}
      </div>
    </div>
  );
}
