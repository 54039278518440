import { MdEditor } from "md-editor-rt";
import "md-editor-rt/lib/style.css";
import { FC, useEffect, useState } from "react";
import { Viewer } from "../components/sample/Viewer";

const sampleContent = `# Sample Markdown

<span style="color:red;">your text here in red</span>
<span style="color:#520099;">or make it blue</span>
<p style="background:#520099; color:red;">color and background</p>
<p style="background:#520099; color:red; text-align: center;">color and background</p>

<p style="text-align: center;">Text_content</p>

![](https://i.ibb.co/RvLwj5D/3495be3e27b0.jpg)`;

export default function Sample() {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [content, setContent] = useState<string>(sampleContent);
  useEffect(() => {
    const interval = setInterval(() => {
      const isSidebarOpen = localStorage.getItem("sidebar") === "true";
      setSidebarOpen(isSidebarOpen);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div
      className={`${sidebarOpen ? "w-[calc(100vw-4rem)]" : "w-[calc(100vw-20rem)]"} mb-20`}
    >
      <MdEditor
        modelValue={content}
        className=""
        toolbarsExclude={["save", "catalog", "htmlPreview", "github"]}
        onChange={setContent}
        language="en-US"
      />
      <Viewer content={content} />
    </div>
  );
}
