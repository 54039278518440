export const getImageUrl = (path: string) => {
  return new URL(`/assets/${path}`, import.meta.url).href;
};

// image is file from input type file
export const uploadImageBase64 = async (base64: string) => {
  const imageData = base64.split(",")[1];
  const formData = new FormData();
  formData.append("image", imageData);
  const baseUrl = "https://api.imgbb.com/1/upload";
  const token = "8d5ec1b725367b5dbc5d563d74ee9e07";
  const url = `${baseUrl}?key=${token}`;
  const response = await fetch(url, {
    method: "POST",
    body: formData,
  });
  const json = await response.json();
  if (json.status !== 200) {
    alert("Error uploading image");
    return "";
  }
  return json.data.url;
};

export const fileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target?.result);
    };
    reader.readAsDataURL(file);
  });

// Utility function for converting an image file to JPEG base64
export const convertToJPEGBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const canvas = document.createElement("canvas");
    const reader = new FileReader();

    reader.onload = (e) => {
      // Ensure that the result is a string before assigning it to the Image src
      if (typeof e.target?.result === "string") {
        img.src = e.target.result;
      } else {
        // Handle the error or make a default assignment
        console.error("FileReader result is not a string.");
        // Optionally, set a default image or handle the error appropriately
        // img.src = 'path/to/default/image.jpg';
      }
    };

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx!.drawImage(img, 0, 0, canvas.width, canvas.height);
      resolve(canvas.toDataURL("image/jpeg", 0.99)); // Convert canvas to JPEG at 92% quality
    };

    img.onerror = reject;
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
