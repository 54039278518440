export const DateTimeString = (date: Date) => {
  const pad = (num: any) => (num < 10 ? `0${num}` : num);

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are 0-indexed
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const GetDate = (date: Date) => {
  const pad = (num: any) => (num < 10 ? `0${num}` : num);

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are 0-indexed
  const day = pad(date.getDate());

  return `${year}-${month}-${day}`;
};

// get timestamp from year, month, day: YYYY-MM-DD
export const GetTimestamp = (date: string) => {
  const [year, month, day] = date.split("-");
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day)).getTime();
};
