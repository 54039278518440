import { useState } from "react";
import { FiEdit, FiTrash } from "react-icons/fi";
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import Post from "../../types/PostType";
import { createOrUpdatePost, deletePost } from "../../utils/firebase";
import { toNonAccentVietnamese } from "../../utils/string";
import { DropDown } from "./DropDown";

export const PostCard = ({ post }: { post: Post }) => {
  const navigate = useNavigate();
  const [visibility, setVisibility] = useState(post.visibility);

  let navigateUrl = post.title.replace(/\s+/g, "-").toLowerCase();
  navigateUrl = toNonAccentVietnamese(navigateUrl);

  const dropDownData = [
    {
      name: "Edit",
      icon: <FiEdit className="h-5 w-5 text-gray-900" />,
    },
    {
      name: "Delete",
      icon: <FiTrash className="h-5 w-5 text-gray-900" />,
    },
  ];

  const handleVisibility = async () => {
    setVisibility(!visibility);
    post.visibility = !visibility;
    await createOrUpdatePost(post);
  };

  const handleDropDown = async (e: any) => {
    if (e === "Edit") {
      navigate(`/blog/${navigateUrl}`);
    } else if (e === "Delete") {
      await deletePost(post.title);
      window.location.reload();
    }
  };

  return (
    <div>
      <div className="mb-5 max-h-[400px] max-w-[300px] rounded-lg border border-gray-200 bg-white shadow-md">
        <div className="relative">
          <Link to={navigateUrl}>
            <div className="relative">
              <img
                className="w-auto object-cover rounded-t-lg h-52"
                src={post.coverImage}
                alt=""
              />
            </div>
          </Link>
          <button
            className="absolute left-2 top-2 flex h-6 w-6 items-center justify-center rounded-md bg-white hover:bg-slate-200"
            onClick={handleVisibility}
          >
            {visibility ? (
              <PiEyeLight className="h-5 w-5 text-gray-900" />
            ) : (
              <PiEyeSlash className="h-5 w-5 text-gray-500" />
            )}
          </button>
          <div className="absolute right-2 top-2">
            <DropDown
              dropDownData={dropDownData}
              itemClicked={handleDropDown}
            />
          </div>
        </div>
        <div className="p-5">
          <Link to={navigateUrl} className="cursor-pointer">
            <h5 className="mb-2 line-clamp-1 text-xl font-bold tracking-tight text-gray-900">
              {post.title}
            </h5>
          </Link>
          <p className="mb-3 line-clamp-3 font-normal text-gray-700">
            {post.content}
          </p>
        </div>
      </div>
    </div>
  );
};
